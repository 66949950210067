<template>
<!-- Bootstrap CDN import -->
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
    <div class="detail">
        <h4> {{niceDate(beginDate)}} </h4>
        <table class="table-sm align-left">
          <thead>
            <tr>
              <th class="align-right" colspan=4>Total:</th>
              <th>${{ total.toFixed(2) }}</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="item in itemList" :key="item.id">
                  <td>{{niceDate(item.entry_date)}}</td>
                  <td>{{item.product}}</td>
                  <td>{{item.quantity}}</td>
                  <td>{{item.measurement}}</td>
                  <td>${{item.extended_price.toFixed(2)}}</td>
              </tr>
          </tbody>
        </table>
        
    </div>
</template>
<script>
import DataService from '@/services/DataService.js'

export default {
    name: 'Details',
    props: {
        beginDate: {
            type: String,
            default: ""
        }
    },
    data() {
      return {
        itemList: [],
        total: 0
      }
    },
    created () {
        this.getDetails()
    },
    methods: {
        async getDetails(){
          const result = await DataService.getDetails(this.beginDate)
          this.itemList = result.data
          console.log('details:', this.itemList)

          let grand_total = 0; 
          this.itemList.forEach(element => {
             grand_total += element.extended_price
          });
          this.total = grand_total
        },
        niceDate(value) {
          if (!value) return;

          const dateArray = value.split("-");
          // 2019-09-20 -> 09/20/19
          return `${dateArray[1]}/${dateArray[2]}/${dateArray[0][2]}${dateArray[0][3]}`;
        },
    }
    
}
</script>
<style scoped>
.detail {
  text-align: left;
}
th.align-right {
  text-align: right;
}
</style>