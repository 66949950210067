import { createApp } from 'vue'
import Toast from "vue-toastification"
import App from './App.vue'
import router from './router'
import store from './store'

// Import the toastification CSS
import "vue-toastification/dist/index.css"

//const options = {
//        transition: "Vue-Toastification__bounce",
//        maxToasts: 20,
//        newestOnTop: true
//}

createApp(App).use(store).use(router).use(Toast).mount('#app')

//App.use(Toast, options)
//App.use(Toast)
