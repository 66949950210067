<template>
<!-- Bootstrap CDN import -->
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
  <div class="summary">
    <h2>Summary</h2>
    <table width="30%" class="table-sm table-striped table-hover">
      <thead>
       <tr>
         <th class="align-right">Total:</th>
         <th>${{ total.toFixed(2) }}</th>
      </tr>
      </thead>
      <tbody>
       <tr class="hoverable" @click="gotoDetails(item.entry_date)" v-for="item in summary_data" :key="item.id">
           <td>
               {{niceDate(item.entry_date)}}
           </td>
           <td>
               ${{ item.total.toFixed(2) }}
           </td>
       </tr>
       <!-- if no data in array -->
       <tr v-if="summary_data.length==0">
         <td>No data for selected period.</td>
         <td></td>
       </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DataService from '@/services/DataService.js'

export default {
  name: 'Summary',
  props: {
    beginDate: {
      type: String,
      default: ""
    },
    endDate: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      summary_data: [],
      total: 0 
      }
    },
  watch: {
    beginDate: {
      immediate: true,
      deep: true,
      handler() {
        this.getSummary();
      }
    }
  },
  methods:{
    async getSummary() {
      // hold if if not set
      if (this.beginDate === "")
        return

      const result = await DataService.getSummary(this.beginDate, this.endDate)
      this.summary_data = result.data
      console.log('summary:', result.data)
      
      let grand_total = 0; 
      this.summary_data.forEach(element => {
         grand_total += element.total
      });
      this.total = grand_total
    },
    gotoDetails(value) {
      console.log("gotoDetails: ", value)
      this.$router.push({
        name: "Details",
        params: {beginDate: value }
      })

    },
    niceDate(value) {
      if (!value) return;

      const dateArray = value.split("-");
      // 2019-09-20 -> 09/20/19
      return `${dateArray[1]}/${dateArray[2]}/${dateArray[0][2]}${dateArray[0][3]}`;
    },

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.summary {
  text-align: left;
}
th.align-right {
  text-align: right;
}
.hoverable {
  cursor: pointer;
}
</style>
