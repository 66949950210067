import axios from 'axios'

const apiClient = axios.create({
    //baseURL: 'http://localhost:8000/inventory',
    //baseURL: 'http://puny:8000/inventory',
    baseURL: 'https://ims-api.asskick.com/inventory',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    getItemList() {
        return apiClient.get('/counts/list/')
    },
    getCategories() {
        return apiClient.get('/categories/')
    },
    getProductsInCategory(category) {
        return apiClient.get(`/categories/${category}/products/`)
    },
    getSummary(begin_date, end_date) {
        return apiClient.get(`/counts/summary/?begin_date=${begin_date}&end_date=${end_date}`)
    },
    getDetails(begin_date) {
        return apiClient.get(`/counts/detail/?begin_date=${begin_date}&end_date=${begin_date}`)
    },
    sendCounts(payload) {
        return apiClient.post('/counts/', payload)
    }
}