<template>
  <div class="count-list">
    <Summary/>
  </div>
</template>

<script>
// @ is an alias to /src
import Summary from '@/components/Summary.vue'

export default {
  name: 'Counts',
  components: {
    Summary
  }
}
</script>
