import { createRouter, createWebHistory } from 'vue-router'
import Counts from '../views/Counts.vue'
import CountsList from '../views/CountsList.vue'
import Reports from '../views/Reports.vue'
import Details from '../views/Details.vue'

const routes = [
  {
    path: '/',
    name: 'CountsList',
    component: CountsList 
  },
  {
    path: '/counts',
    name: 'Counts',
    component: Counts
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/details',
    name: 'Details',
    component: Details,
    props: true  // so route.params are passed as props
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
