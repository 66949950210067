<template>
  <div class="Reports">
    <h1>This is the Reports page</h1>
  </div>
    <DateSelect />
</template>

<script>
import DateSelect from '@/components/DateSelect'

export default {
   components: {
     DateSelect
   }
  
}
</script>
<style scoped>
  
</style>
