<template>
<!-- Bootstrap CDN import -->
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
  <div class="counts">
    <h2>Enter Counts</h2>
    <table class="table table-sm">
      <thead> </thead>
      <tbody>
      <div v-for="category in categories" v-bind:key="category.id">
       <tr><td class="category-name"> {{ category.name }} </td></tr>
        <div v-for="item in itemList[category.id]" v-bind:key="item.id">
             <tr>
               <td class="item-name"> {{ item.name }} </td>
               <td>
                   <div class="line-of-items" v-for="measurement in item.measurements" v-bind:key="measurement.id">
                       <input type="number" v-bind:name="item.id" v-bind:id="measurement.id">
                       <label class="line-of-items" v-bind:for="measurement.id">{{measurement.unit.name}}</label>
                       <div class="bottom-label">{{ measurement.description }}</div>
                   </div>
               </td>
             </tr>
          </div>
        </div>
      </tbody>
    </table>
    <button @click="sendCounts">Save</button>
  </div>
</template>

<script>
import DataService from '@/services/DataService.js'
import { useToast } from "vue-toastification"

export default {
  name: 'CountsList',
  // props: {
  //   msg: String
  //},
  data() {
    return {
      categories: null,
      itemList: [],
      allItemList: [],
      today: new Date().toLocaleDateString('en-CA')
    }
  },
  setup() {
      // Get toast interface
      const toast = useToast();

      // Use it!
      //toast("I'm a toast!");

      // or with options
      //toast.success("My toast content", {
      //  timeout: 2000
      //});
      // These options will override the options defined in the "app.use" plugin registration for this specific toast

      // Make it available inside methods
      return { toast }

  },
  created() {
    this.getCategories()
      // DataService.getCategories()
      //     .then(response => {
      //     this.categories = response.data
      //     console.log(`response: ${response.data}`)

      //     // for( let category in this.categories) {
      //     //   console.log(`category: ${category.id}`)
      //     //   //this.getItems(category['id'])
      //     // }
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })
    DataService.getItemList()
        .then(response => {
        this.allItemList = response.data
        console.log('allItems:', this.allItemList)
        this.getItems()
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    async getCategories() {
          const result = await DataService.getCategories()
          this.categories = result.data
          console.log('categories:', this.categories)

    //      this.getItems()
    },
     getItems(){
          
          let result = []
          for(let i in this.allItemList){
            console.log("item", this.allItemList[i])
            let category = this.allItemList[i].category
            let itemObj = this.allItemList[i]
            console.log("category", category)
            console.log("itemObj", itemObj)
            if (! result[category])
               result[category] = []
            result[category].push(itemObj)
          }
          this.itemList = result
          console.log("finished: ", result)
      },
    // This works.
    // async getItems(){
    //   console.log("in getItems():")
    //   console.log("categories:", this.categories)
    //   let result = []
    //   for(let i in this.categories){
    //     console.log("index", this.categories[i].id)
    //     const res = await DataService.getProductsInCategory(this.categories[i].id)
    //     result[this.categories[i].id] = res.data
    //     console.log(res.data)
    //   }
    //   this.itemList = result
    // },

    async sendCounts() {
      let elements = document.querySelectorAll("input[type=number]")
      let counts = []

      for (let i=0; i<elements.length; i++) {
          let element = elements[i]
          if (element.value) {
              console.log("got one: ", element.name, element.id, element.value)
              let itemCount = {
                  "product": element.name,
                  "measurement": element.id,
                  "quantity": element.value
              }
              counts.push(itemCount)
          }
      }

      try {
        const response = await DataService.sendCounts(counts)
        console.log("response:", response.status)
        if(response.status == 201) {
          this.toast.success("Success! Counts added.")

          // send to details page
          this.$router.push({
            name: "Details",
            params: {beginDate: this.today }
          })

        }
        else
          this.toast.error(`Something went wrong! Server returned error: $response.status`)
        //console.log("the counts:", counts)
      } catch (error) {
          console.log("major error:", error)
          this.toast.error(`Major error! ${error}`)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.counts {
  text-align: left;
}
input[type="number"] {
   width:50px;
   font-size: smaller;
}
label.line-of-items {
  margin: 0;
  vertical-align: center;
  padding-left: 5px;
}
div.line-of-items {
  display: inline-block;
  vertical-align: middle;
  padding-right: 30px;
}
div.bottom-label {
  /*
    padding-top: 3px;
  */
    font-style: italic;
    font-size: x-small;
    padding-bottom: 3px;
}
.category-name {
    border-top-style: none;
    font-weight: bold;
    /*
    padding-top: 5px;
  */
    padding-bottom: 5px;
}
.item-name {
    vertical-align: top;
    /*
    padding-top: 5px;
  */
    width: 175px;
    padding-left: 30px;
}
</style>
