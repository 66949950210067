<template>
<!-- Bootstrap CDN import -->
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
     <div class="date-select">
       <span>
         <label for="begin_date">Begin Date:</label>
         <input type="date" v-model="beginDateEntry" name="begin_date" id="begin_date">
         &nbsp; &nbsp; &nbsp; &nbsp;
         <label for="begin_date">End Date:</label>
         <input type="date" v-model="endDateEntry" name="end_date" id="end_date">
         <button @click="setDates">Go</button>
       </span>
    </div>
    <Summary :beginDate="beginDate" :endDate="endDate"/>
</template>
<script>
import Summary from '@/components/Summary'

export default {
  components: {
    Summary
  },
  data() {
    return {
      beginDate: "", 
      endDate: "",
      beginDateEntry: "", 
      endDateEntry: ""
      }
  },
  methods: {
    setDates() {
      this.beginDate =  this.beginDateEntry
      this.endDate =  this.endDateEntry ? this.endDateEntry : this.beginDateEntry
    }
  }
    
}
</script>
<style scoped>

</style>